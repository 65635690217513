<template>
    <div class="installPage">
        <el-card shadow="never" class="elementCard">
            <!-- 搜索 -->
            <div class="search-box">
                <el-form ref="searchForm" :model="searchParams" inline>
                    <el-form-item label="所属组织">
                        <el-cascader
                            clearable
                            placeholder="请选择所属组织"
                            v-model="streetArr"
                            :props="defaultProps"
                            class="w300"
                            ref="supervisedUnitCode"
                            :show-all-levels="false"
                            filterable
                            :options="streetList"
                        ></el-cascader>
                    </el-form-item>
                    <el-form-item label="关键字">
                        <el-input v-model="searchParams.keyWords" clearable class="w300" placeholder="请输入安装用户/联系方式"></el-input>
                    </el-form-item>
                    <el-form-item label="时间范围">
                        <el-date-picker
                            v-model="timeRange"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            clearable
                            class="w300"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="安装设备">
                        <el-select v-model="searchParams.equipments" placeholder="请选择安装设备" class="w300" clearable>
                            <el-option v-for="item in EQUNAME" :key="item.id" :label="item.label" :value="item.value" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="doSearch">搜索</el-button>
                    </el-form-item>
                    <el-form-item style="float: right">
                        <el-button type="primary" plain @click="doExport">导出</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <!-- 安装列表 -->
            <el-table v-loading="loading" class="table" :data="tableData" border :header-cell-style="headerCellStyle" @sort-change="sortChangeInit">
                <template slot="empty">
                    <IsEmpty />
                </template>
                <el-table-column prop="userName" label="安装客户" width="120" align="center" />
                <el-table-column prop="equipments" label="安装设备" width="150" align="center" />
                <el-table-column prop="userPhone" label="联系方式" width="150" align="center" />
                <el-table-column prop="tenantOrgName" label="所属组织" width="180" :show-overflow-tooltip="true" align="center" />
                <el-table-column prop="detaAddress" label="详细地址" width="180" :show-overflow-tooltip="true" align="center" />
                <el-table-column prop="urgentPeople" label="紧急联系人" width="120" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.urgentConcatInfos && scope.row.urgentConcatInfos.length > 0 ? scope.row.urgentConcatInfos[0].urgentPeople : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="urgentPhone" label="紧急联系人电话" width="150" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.urgentConcatInfos && scope.row.urgentConcatInfos.length > 0 ? scope.row.urgentConcatInfos[0].urgentPhone : '' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="finishDate" label="安装日期" width="120" align="center" sortable="custom" />
                <el-table-column prop="installWorker" label="安装人员" align="center" />
                <el-table-column prop="installerPhone" label="安装人员联系电话" width="150" align="center" />
                <el-table-column prop="status" label="用户签名" width="150" align="center">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.userSign" :preview-src-list="[scope.row.userSign]">
                            <div slot="error" />
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="安装状态" width="120" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status === 1">待分派</span>
                        <span v-if="scope.row.status === 2">已分派</span>
                        <span v-if="scope.row.status === 3">安装中</span>
                        <span v-if="scope.row.status === 4">已完成</span>
                        <span v-if="scope.row.status === 5">已回访</span>
                        <span v-if="scope.row.status === 6">已撤销</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="250" fixed="right" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" @click="toLook(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
                v-if="tableData.length > 0"
                style="margin-top: 20px"
                background
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pageParams.pageSize"
                :current-page="pageParams.pageNum"
                layout="total, sizes, prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
            />
        </el-card>
        <Details v-if="info_show" :visible="info_show" :order-id="orderId" :info-item="infoItem" @closeDialog="info_show = false" />
    </div>
</template>

<script>
import Details from './details.vue'
import { installPage, installPageDown } from '@/api/homeSave'
import { streetList } from '@/api/orderManage'
import { EQUNAME } from '@/data/dataDictionary'
import { downloadStream } from '@/utils/index'
export default {
    // 安装管理
    name: 'installPage',
    components: { Details },
    data() {
        return {
            EQUNAME, // 设备名称
            loading: false,
            timeRange: '',
            searchParams: {
                // 搜索参数
                keyWords: '',
                equipments: '',
                sort: null
            },
            total: 2,
            pageParams: {
                // 分页参数
                pageNum: 1,
                pageSize: 10
            },
            tableData: [],
            orderId: null,
            infoItem: null,
            info_show: false,
            streetArr: '',
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            },
            streetList: []
        }
    },
    mounted() {
        this.getInstallList()
        this.getStreetList()
    },
    methods: {
        // 获取组织列表
        getStreetList() {
            streetList().then(res => {
                res.data.forEach(item => {
                    item.streetNameListResultList.forEach(itemC => {
                        if (itemC.streetNameListResultList.length === 0) {
                            delete itemC.streetNameListResultList
                        }
                    })
                })
                this.streetList = res.data
            })
        },
        doSearch() {
            this.pageParams.pageNum = 1
            this.getInstallList()
        },
        sortChangeInit(column) {
            this.searchParams.sort = column.order
            this.doSearch()
        },
        // 分页
        handleSizeChange(size) {
            this.pageParams.pageSize = size
            this.getInstallList()
        },
        handleCurrentChange(page) {
            this.pageParams.pageNum = page
            this.getInstallList()
        },
        getInstallList() {
            this.loading = true
            const params = Object.assign({
                ...this.searchParams,
                ...this.pageParams,
                startTime: this.timeRange ? this.timeRange[0] : '',
                endTime: this.timeRange ? this.timeRange[1] : '',
                streetId: this.streetArr.length > 0 ? this.streetArr[0] : '',
                deptId: this.streetArr.length > 1 ? this.streetArr[1] : ''
            })
            installPage(params)
                .then(res => {
                    this.tableData = res.data.records
                    this.total = res.data.total
                    this.loading = false
                })
                .catch(() => {
                    this.tableData = []
                    this.total = 0
                    this.loading = false
                })
        },
        toLook(order) {
            this.orderId = order.orderId
            this.infoItem = order
            this.info_show = true
        },
        // 安装管理列表导出
        async doExport() {
            const params = Object.assign({
                ...this.searchParams,
                ...this.pageParams,
                startTime: this.timeRange ? this.timeRange[0] : '',
                endTime: this.timeRange ? this.timeRange[1] : ''
            })
            const res = await installPageDown(params)
            downloadStream(res, `安装管理列表.xlsx`)
        }
    }
}
</script>

<style></style>
